









import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
@Component({
    components: {},
})
export default class AdminMenu extends Vue {
    userInfo: any = {}

    beforeMount(){
        // アドミン権限がないユーザは弾く
        Common.getUserInfo().then((userInfo: any) => {
            if (userInfo.isAdmin == true) {
                this.userInfo = userInfo
                return
            }
            this.$router.push('/404')
        })
    }
    mounted () {
        this.mainRegistUserDownloadURL()
    }
    mainRegistUserDownloadURL() {
        return 'https://asia-northeast1-' + process.env.VUE_APP_FIREBASE_PROJECT_ID + '.cloudfunctions.net/exportUserDataForSF?uid='
    }
}
